.tasks {
    margin-top: 30px;
    padding: 20px;
    position: relative;
}

.step-num {
    position: absolute;
    right: 10px;
    top: 0px;
    margin-top: 0px;
    color: #ade7b7;
}


