.Checklist {
    width: calc(50% - 10px);
    padding: 10px;
}

.listitem-text {
    text-decoration: line-through;
}



@media screen and (min-width: 720px) {
    .Checklist {
        width: calc(50% - 10px);
        padding: 10px;
    }
}

@media screen and (max-width: 720px) {
    .Checklist {
        width: calc(100% - 20px);
        margin-bottom: 20px;
        padding: 10px;
    }
}