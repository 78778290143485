.funnel-section {
  width: calc(100% - 40px);
  position: relative;
  height: fit-content;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.funnel-img {
  width: calc(100% - 100px);
  margin: 0px;
}

.markers {
  position: absolute;
  top: 0px;
  right: -45px;
  height: calc(100% - 5px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.spacer {
  height: 23px;
}

.marker {
}
