.Modules {
    padding: 10px;

}

.modCard {
    margin-top: 20px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;

     
}



.item {
    margin: 10px;
    max-width: 350px;
    min-width: 250px;
    padding: 10px;
    position: relative;
    text-align: left;
    font-family: roboto;
    text-transform:initial;
    height: fit-content;
}

.item .title {
    margin-top: 30px;
    max-width: calc(100% - 30px);
}


.item .desc {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    height: 93px;
    width: calc(100%);
    text-overflow:ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 4;


}

.effectiveBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;

}

.effectiveItem {
    width: calc(20% - 2px);
    height: 5px;
    background-color: #ade7b7;

}

.blank {
    background-color: #ade7b7;
    opacity: 0.3;
}

.topIcons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    width: calc(100% - 20px);
}

.icons {
    display: flex;
    flex-direction: column;
    justify-content: start;
}
