.resources {
    margin-bottom: 40px;
    padding: 20px;
    margin-top: 30px;
}


.title {
    margin-top: 0px;
    margin-bottom: 0px;
}
.resources .listitem {
border-bottom: solid 1px #ade7b7;
border-radius: 5px;
}