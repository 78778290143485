.subscribe-page {
  width: calc(100vw - 30px);
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Subscribe-card {
  max-width: calc(100%);
  width: 500px;
  padding: 15px;
  position: relative;
}

.loading {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 500;
}

.status-bar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  
}

.expire-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 30px;
    margin-top: 10px;
}


.subscription-banner {
    width: calc(100% - 30px);
    padding: 15px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.subscription-card {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding-left: 50px;
    padding-right: 50px;

}


.text-section {
    margin-left: 20px;

}

.subtitle-msg {
    opacity: 0.5;

}

.subtitle-amount {
    font-weight: bold;

}


.iconCard {
    padding: 10px;
    width: fit-content;
    height: fit-content;
    padding-bottom: 3px;
    background-color: #ade7b7;

}



.subscribe-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.subscribe-button {
    font-weight: bold;
    color: black;
    background-color: #ade7b7;
}
