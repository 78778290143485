

@media  screen and (max-width: 800px) {
  .blob {
    position: absolute;
    height: 30%;
    z-index: 0;
    opacity: 50%;
  }

  .blobTwo {
    right: 0%;
    top: 0%;
  }
  
  .blobThree {
    left: 0%;
    bottom: 0%;
  
  }
}


@media  screen and (min-width: 800px) {
.blob {
  position: absolute;
  height: 50%;
  z-index: 0;
  opacity: 50%;
}

.blobTwo {
  right: 5%;
  top: 5%;
}

.blobThree {
  left: 5%;
  bottom: 5%;

}
}










.register {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .register__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
    z-index: 10;
    
  }
  .register__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .register__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: black;
  }
  .register__google {
    background-color: #4285f4;
  }
  .register div {
    margin-top: 7px;
  }

  .terms-text {
    padding: 20px;
  }

  .terms-text > p {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .bold {
    font-weight: bold;
    padding-top: 10px;
  }

  .inset {
    padding-left: 20px;
    padding-bottom: 5px;
  }