.module {
display: flex;
flex-direction: row;
justify-content: center;
width: calc(100vw - 20px); 
margin-top: 20px;
margin-left: 10px;
 
}

.fabButton {
    position:fixed;
    right: 10px;
    bottom: 10px;
}

.child {
    width: 100%;
    max-width: 800px;

}




@media screen and (min-width: 720px) {
    .first {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
}

@media screen and (max-width: 720px) {
    .first {
   
}
}