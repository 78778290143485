

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }



@media screen and (min-width: 720px) {
  .video {
      width: calc(50% - 20px);
  }
}

@media screen and (max-width: 720px) {
  .video {
      width: calc(100%);
      margin-bottom: 20px;
  }
}
  