

.dashboard {
      padding-top: 30px;
    position: relative;
}



.welcome-msg {
  text-align: right;
  font-weight: bold;
  font-size: x-large;
  opacity: 0.3;

}


@media screen and (max-width: 1100px) {
  .dashboard {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    position: relative;
  }

  .welcome {
    position: absolute;
    top: 0px;
    left: 20px;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    justify-content: end;
  }

  
  .dash-left {
  
  }
  
  .dash-funnel-card {
    padding: 15px;
    margin: 20px;
    margin-bottom: 0px;
    width: calc(100vw - 70px);
  
  }
  
  .dash-right {
  
  
  }
  
  .dash-quoutes-card {
    padding: 15px;
    margin: 20px;
    width: calc(100vw - 70px);
  
  }
  
  .dash-events-card {
    padding: 15px;
    margin: 20px;
    width: calc(100vw - 70px);
  
  }
  
  .dash-events-card > h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  
  .dash-quotes-p-q {
    margin-bottom: 0px;
    font-weight: 500;
  
  }
  
  .dash-quotes-p-a {
    text-align: right;
    font-weight: bold;
    font-size: large;
    margin-top: 0px;
  
  }
  
  
  .events-list {
    width: 100%;
    background-color: rgba(211, 211, 211, 0.2);
    border-radius: 5px;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  
}

@media screen and (min-width: 1100px) {

  .welcome {
    position: absolute;
    top: 0px;
    left: 20px;
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .welcome-msg {
    width: calc(48vw + 30vw + 60px);
   
  }


  .dashboard {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    justify-content: center;
    height: calc(100vh - 200px);
  }
  
  .dash-left {
  
  }
  
  .dash-funnel-card {
    padding: 15px;
    margin: 20px;
    width: calc(48vw - 40px);
  
  }
  
  .dash-right {
  
  
  }
  
  .dash-quoutes-card {
    padding: 15px;
    margin: 20px;
    width: 30vw;
  
  }
  
  .dash-events-card {
    padding: 15px;
    margin: 20px;
    width: 30vw;
  
  }
  
  .dash-events-card > h3 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  
  .dash-quotes-p-q {
    margin-bottom: 0px;
    font-weight: 500;
  
  }
  
  .dash-quotes-p-a {
    text-align: right;
    font-weight: bold;
    font-size: large;
    margin-top: 0px;
  
  }
  
  
  .events-list {
    width: 100%;
    background-color: rgba(211, 211, 211, 0.2);
    border-radius: 5px;
    height: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  
}