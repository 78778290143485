.Header {
    z-index: 10000;
    width: calc(100vw - 20px);
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

}

.left {

}

.right {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;

}

.Header .right > h2 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 20px;
}



.Header .nav-menu {
    background-color: #ade7b7;
    position: fixed;
    right: 0px;
    top: 70px;
    width: 250px;
    height: calc(100vh - 60px);
    transition: all 320ms linear;
    z-index: 10;   
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;


}

.Header .nav-menu-closed {
    background-color: #ade7b7;
        position: fixed;
    right: -250px;
    top: 70px;
    width: 250px;
    height: calc(100vh - 60px);
    transition: all 320ms linear;
    z-index: 10;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;


}


.Header .nav-icon {
    margin-right: 15px;
    background-color: red;
    margin-bottom: 0px;
    padding-bottom: 0px;
}


.Header .logout {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    padding: 10px;
    font-size: large;
    cursor: pointer;
    color:black;
    margin: 10px;
    border-radius: 5px;
    font-weight: bold;
}

.Header .logout:hover {
    background-color: white;
}


